<script lang="ts" setup>
import { reactive } from 'vue'
import { Btn, TextField, PhoneField, Dropdown } from '@keyo/ui'
import { useOrganizationsStore } from '@/store/organizations'
import useModal from '@/composables/useModal'
import { useCountries } from '@/utils/countriesList'
import toast from '@/libs/toast'
import { required, phone } from '@keyo/core/validations'
import useForm from '@/composables/useForm.ts'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import { useI18n } from 'vue-i18n'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import type { AxiosError } from 'axios'

interface Form {
  name: string
  dba: string
  tin: string
  shipping_address: {
    country: string
    state: string
    city: string
    zip_code: string
    street: string
  }
  business_phone: string
}

const { t } = useI18n({ useScope: 'global' })
const modal = useModal()
const { create } = useOrganizationsStore()
const { handleResponseException } = useFormHelpers()

const initialForm = reactive<Form>({
  name: '',
  dba: '',
  tin: '',
  shipping_address: {
    country: '',
    state: '',
    city: '',
    zip_code: '',
    street: '',
  },
  business_phone: '',
})

const validationRules = {
  name: [required()],
  shipping_address: {
    country: [required()],
    state: [required()],
    city: [required()],
    zip_code: [required()],
    street: [required()],
  },
  business_phone: [phone()],
}

const { form, errors, isSubmitting, submitForm, externalResults } = useForm(
  initialForm,
  validationRules,
)
const { countriesOptions } = useCountries()

const submit = async () => {
  await submitForm(async () => {
    try {
      await create(form)
      modal.hide()
      toast.show(() => t('components.modals.createOrganization.created'), 'success')
    } catch (error) {
      const { response } = error as AxiosError
      if (response?.status === 403) {
        toast.show(() => t('common.noPermission'), 'error')
        modal.hide()
        return
      } else {
        handleResponseException(response, externalResults)
        toast.show(() => t('components.modals.createOrganization.failedCreated'), 'error')
      }
    }
  })
}
</script>

<template>
  <div class="create-organization">
    <h2 class="title text-heading-xl">
      {{ $t('components.modals.createOrganization.title') }}
    </h2>
    <p class="text text-body-m">
      {{ $t('components.modals.createOrganization.subtitle') }}
    </p>
    <form @submit.prevent="submit">
      <div class="input-group">
        <TextField
          id="name-m"
          v-model="form.name"
          :label="`${$t('common.officialName')}*`"
          :state="errors.name ? 'error' : ''"
          :tip="errors.name"
        />
        <TextField
          id="dba-m"
          v-model="form.dba"
          :label="$t('common.dba')"
          :state="errors.dba ? 'error' : ''"
          :tip="errors.dba"
        />
      </div>
      <div class="input-group">
        <TextField
          id="tin-m"
          v-model="form.tin"
          :label="$t('common.tinIdOrRegistration')"
          :state="errors.tin ? 'error' : ''"
          :tip="errors.tin"
        />
        <PhoneField
          id="business-phone-m"
          v-model="form.business_phone"
          :label="$t('common.phoneNumber')"
          :state="errors.business_phone ? 'error' : ''"
          :tip="errors.business_phone"
        />
      </div>
      <div class="input-group">
        <Dropdown
          id="country-m"
          v-model="form.shipping_address.country"
          searchable
          :options="countriesOptions"
          :label="`${$t('common.country')}*`"
          :state="errors.shipping_address?.country ? 'error' : ''"
          :tip="errors.shipping_address?.country"
        />
        <TextField
          id="state-m"
          v-model="form.shipping_address.state"
          :label="
            form.shipping_address.country === 'US'
              ? `${$t('common.stateUs')}*`
              : `${$t('common.stateRegion')}*`
          "
          :state="errors.shipping_address?.state ? 'error' : ''"
          :tip="errors.shipping_address?.state"
        />
      </div>
      <div class="input-group">
        <TextField
          id="city-m"
          v-model="form.shipping_address.city"
          :label="`${$t('common.city')}*`"
          :state="errors.shipping_address?.city ? 'error' : ''"
          :tip="errors.shipping_address?.city"
          capitalize
        />
        <TextField
          id="zip-m"
          v-model="form.shipping_address.zip_code"
          :label="`${$t('common.zip')}*`"
          :state="errors.shipping_address?.zip_code ? 'error' : ''"
          :tip="errors.shipping_address?.zip_code"
        />
      </div>
      <TextField
        id="street-m"
        v-model="form.shipping_address.street"
        :label="`${$t('common.street')}*`"
        :state="errors.shipping_address?.street ? 'error' : ''"
        :tip="errors.shipping_address?.street"
      />

      <NonFieldErrors :errors="externalResults.non_field_errors" />

      <div class="buttons">
        <Btn type="submit" :loading="isSubmitting">
          {{ $t('buttons.create') }}
        </Btn>
        <Btn kind="minimal" @click="modal.hide"> {{ $t('buttons.cancel') }} </Btn>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.create-organization {
  width: 100%;
  max-width: 50rem;
}

form {
  display: grid;
  grid-row-gap: 1.5rem;

  :deep(.dropdown) {
    --dropdown-menu-height: 15rem;

    background-color: var(--color-grey-200);

    &:hover {
      border: 1px solid var(--color-grey-400);
    }
  }
}

.title {
  margin-bottom: 0.8rem;
}
.text {
  margin-bottom: 1.5rem;
}
.input-group {
  display: grid;
  grid-row-gap: 1.5rem;
  grid-template-columns: 1fr;
  grid-column-gap: 1.75rem;

  @media screen and (min-width: $mobile) {
    grid-template-columns: 1fr 1fr;
  }
}
.buttons {
  margin: 1.25rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.75rem;
  grid-row-gap: 1.5rem;

  @media screen and (min-width: $mobile) {
    grid-template-columns: 12.5rem 12.5rem;
  }
}
</style>
