import { reactive, ref, watch, computed, inject } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import useFormHelpers from '@/composables/useFormHelpers'
import type { ValidationArgs, ServerErrors, ErrorObject } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import type { FieldError } from '@keyo/core/validations'
import { external } from '@keyo/core/validations'
import { captchaInjectionKey } from '@keyo/core'
import type { Stringable, Captcha } from '@keyo/core'
import type { Ref } from 'vue'

export type UseFormRules<F> = Partial<Record<keyof F, ValidationArgs>>
export type UseFormOptions = {
  captcha?: boolean
}

function addMissingValidationRules<T>(form: T, rules: UseFormRules<T>) {
  for (const key in form) {
    // If the field is an object, recursively add missing validation rules
    if (typeof form[key] === 'object' && form[key] !== null) {
      if (!rules[key]) {
        rules[key] = {}
      }
      addMissingValidationRules(form[key] as T, rules[key] as UseFormRules<T>)
    } else {
      if (!(key in rules)) {
        rules[key] = [external]
      }
    }
  }
  return rules
}

export const formatErrors = (vuelidateErrors: ErrorObject[]) => {
  const formatted = {}

  vuelidateErrors.forEach(({ $propertyPath, $message }) => {
    const pathKeys = $propertyPath.split('.')
    const msgKey = pathKeys.pop() as string
    const last = pathKeys.reduce((o, k) => (o[k] ??= {}), formatted as Record<string, any>)
    last[msgKey] = $message
  })

  return formatted
}

export default function useForm<FormType extends object>(
  initialForm: FormType,
  rules: UseFormRules<FormType>,
  options: UseFormOptions = {
    captcha: false,
  },
) {
  type ExternalErrors = Partial<
    {
      [key in keyof FormType]: FieldError | Stringable
    } & {
      non_field_errors: FieldError
    }
  >

  type Errors<T = FormType> = Partial<{
    [key in keyof T]: T[key] extends object ? Errors<T[key]> : Stringable
  }>

  const captcha = inject(captchaInjectionKey) as Captcha

  const { handleResponseException } = useFormHelpers()

  const isSubmitting = ref(false)
  const errors: Ref<Errors> = ref({})
  const externalResults = reactive<ExternalErrors>({} as ExternalErrors)
  const form = reactive<FormType>({ ...initialForm })

  const hasError = computed(() => v$.value.$error)

  // Automatically add external validation rule for missing fields, including nested validations
  const validationRules = computed(() => addMissingValidationRules<FormType>(initialForm, rules))

  const v$ = useVuelidate(validationRules.value, form, {
    $externalResults: externalResults as ServerErrors,
    $autoDirty: true,
    $rewardEarly: true,
  })

  const resetForm = () => {
    v$.value.$reset()
    v$.value.$clearExternalResults()
    errors.value = {}
  }

  watch(form, () => resetForm(), { deep: true })
  watch(
    () => v$.value.$errors,
    () => {
      errors.value = formatErrors(v$.value.$errors)
    },
    {
      immediate: true,
      deep: true,
    },
  )

  const submitForm = async (submitCallback: () => Promise<void>) => {
    if (isSubmitting.value) return
    isSubmitting.value = true

    resetForm()
    await v$.value.$validate()

    if (v$.value.$error) {
      isSubmitting.value = false
      return
    }

    try {
      if (options.captcha) {
        await captcha?.execute()
        if ('captcha_token' in form) {
          form.captcha_token = captcha?.token.value
        }
      }

      await submitCallback()
    } catch (error) {
      const { response } = error as AxiosError
      handleResponseException(response, externalResults)
    } finally {
      isSubmitting.value = false
    }
  }

  return {
    form,
    isSubmitting,
    externalResults,
    errors,
    hasError,

    resetForm,
    submitForm,
  }
}
