import { apiV3 } from '@/api'
export default {
  list(orgId, search = '') {
    const query = new URLSearchParams({
      limit: 500, // TODO: too many workarounds required to properly handle pagination with other features/logic without proper support from API side.
      offset: 0,
      search,
    })
    query.append('role', 'Admin')
    const url = `/organizations/${orgId}/members/?${query.toString()}`
    return apiV3.get(url)
  },
  ownerTransfer(orgId, { ownerId, code, client_id }) {
    return apiV3.post(`/organizations/${orgId}/members/transfer-ownership/`, {
      new_owner_id: String(ownerId),
      client_id,
      code: code.join(''),
    })
  },
}
