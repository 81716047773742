<script setup lang="ts">
import { inject, onMounted, reactive, ref } from 'vue'
import { DialogBtn, Pin } from '@keyo/ui'

import { useModal } from '@/composables'
import { useAdminsStore } from '../pinia'
import { usePersonalStore } from '@/store'
import useFormHelpers from '@/composables/useFormHelpers'
import accountApi from '@/modules/account/api/index.js'
import { useRoute } from 'vue-router'
import { external, mfaCode, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { BtnResend } from '@/components/BtnResend'
import { getMethodI18nValue } from '@/modules/account/utils'
import type { SignInMethod } from '@/api/auth.ts'
import CardChangeMfaMethod from '@/modules/account/components/CardChangeMfaMethod.vue'
import { I18nT } from 'vue-i18n'
import BtnChangeMfa from '@/components/BtnChangeMfa/BtnChangeMfa.vue'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

type Form = {
  code: string[]
  ownerId: string
  client_id: string
}

const props = defineProps<{
  nextOwner: { id: string; full_name: string }
  method?: SignInMethod
}>()

const captcha = inject(captchaInjectionKey) as Captcha

const { handleResponseException } = useFormHelpers()

const route = useRoute()
const modal = useModal()
const personal = usePersonalStore()
const adminsStore = useAdminsStore()

const isSubmitting = ref(false)
const changingMethod = ref(false)

const form = reactive<Form>({
  code: [],
  ownerId: props.nextOwner.id,
  client_id: '',
})

type Errors = { [key: string]: string } & Partial<Form & { non_field_errors: string }>

const externalResults = reactive<Errors>({})
const rules = {
  code: [required(), mfaCode()],
  ownerId: [external],
  client_id: [external],
}

const method = ref<SignInMethod>(props.method ?? personal.preferableMfaMethod)

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

async function sendCode(currentMethod?: SignInMethod) {
  try {
    await captcha.execute()
    changingMethod.value = false
    method.value = currentMethod ?? method.value

    const { data } = await accountApi.mfaCodeRequest({
      method: method.value,
      action: 'transfer_ownership',
      organization_id: Number(route.params.id),
      captcha_token: captcha.token.value,
    })

    form.client_id = data?.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

const submitForm = async () => {
  if (isSubmitting.value) return
  isSubmitting.value = true

  v$.value.$clearExternalResults()
  v$.value.$reset()

  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }

  try {
    await adminsStore.transferOwnership(route.params.id, form)
    modal.hide()
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(sendCode)
</script>

<template>
  <ModalCard
    v-if="!changingMethod"
    tag="form"
    icon="user-star-filled"
    icon-color="blue"
    size="m"
    :heading="$t('modules.admin.confirmOwnershipTransfer')"
    @submit.prevent="submitForm"
  >
    <template #description>
      <I18nT keypath="modules.admin.confirmOwnershipTransferDesc">
        <template #nextOwner>
          <strong> {{ nextOwner.full_name }} </strong>
        </template>

        <template #method>
          <strong> {{ getMethodI18nValue(method) }} </strong>
        </template>
      </I18nT>
    </template>

    <Pin
      class="pin"
      :state="v$.code.$error ? 'error' : ''"
      :tip="v$.code.$errors?.[0]?.$message"
      @input="form.code = $event"
      @complete="submitForm"
    />

    <BtnResend @click="sendCode" />
    <BtnChangeMfa @click="changingMethod = true" />

    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn danger :loading="isSubmitting" type="submit">
        {{ $t('buttons.confirm') }}
      </DialogBtn>
    </template>
  </ModalCard>

  <CardChangeMfaMethod
    v-else
    :method="method"
    @canceled="changingMethod = false"
    @changed="sendCode"
  />
</template>
